.Privacy .Container {
  text-align: center;
  width: 100%;
  margin-top: 10rem;
}

.Privacy .Container .Text {
  width: 100%;
}

.Privacy .Container .Text .Title {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1;
}

.Privacy .Container .Text .Subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--textColorDarker);
  line-height: 0.1;
}

.Privacy .Container .ActualText {
  margin-top: 3rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.Privacy .Container .ActualText p {
  width: 60%;
  margin-left: 20%;
}

.Privacy .Container .ActualText .Bolded {
  font-weight: 800;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 1.75;
}

.Privacy .Container .ActualText .Highlight {
  color: var(--accentColor);
}

@media (max-width: 1100px) {
  .Privacy .Container {
    margin-top: 7.5rem;
  }
}
