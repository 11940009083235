.Home .TitleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 95%;
}

.Home .TitleContainer h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 0.15;
}

.Home .TitleContainer .Countdown {
  line-height: normal;
  font-size: 2rem;
}

.Home .TitleContainer .W {
  font-size: 0.6rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 0;
  color: var(--accentColor);
  user-select: none;
}

.Home .TitleContainer h2 {
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--textColorDarker);
  width: 100%;
}

.Home .TitleContainer .Buttons {
  margin-top: 2rem;
}

.Home .TitleContainer .Buttons a {
  margin: 0.5rem;
}

.Home .Footer {
  position: fixed;
  bottom: 0%;
  left: 0%;
  margin: 1rem;
  color: var(--textColorEvenDarker);
  opacity: 50%;
  user-select: none;
  font-size: 0.7rem;
}

.Home .SecondContainer {
  position: absolute;
  top: 125%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg);
}

.Home .SecondContainer svg {
  position: absolute;
  top: -9rem;
  left: 0;
  width: 100%;
  height: 15rem;
}

.Home .SecondContainer svg path {
  fill: var(--bg);
}

.Home .SecondContainer .Sex {
  width: 75%;
  position: absolute;
  top: 24rem;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.Home .SecondContainer .Sex h2 {
  font-size: 2.5rem;
  font-weight: 800;
}

.Home .SecondContainer .Sex .Items {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.Home .SecondContainer .Sex .Items .Item {
  /* background-color: rgba(255, 255, 255, 0.04); */
  border-radius: 15px;
  width: 15rem;
  height: 12rem;
  margin: 1rem;
  flex: 40%;
}

.Home .SecondContainer .Sex .Items .Item .Text {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.Home .SecondContainer .Sex .Items .Item .Text .ItemTitle {
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 427px) {
  .Home .SecondContainer {
    height: 185%;
  }
  .Home .SecondContainer .Sex {
    top: 35rem;
  }
}
