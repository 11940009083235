.Alert .Container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 95%;
}

.Alert .Container h1 {
  font-family: "Consolas", monospace;
  font-weight: 800;
  font-size: 4.5rem;
  line-height: 0.1;
}

.Alert .Container h2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 0.75;
}

.Alert .Container p {
  color: var(--textColorDarker);
  font-size: 1.1rem;
}
