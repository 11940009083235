.Dashboard .Title {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.Dashboard .Title h1 {
  font-weight: 800;
  font-size: 2.5rem;
}

.Dashboard .Title p {
  font-weight: 500;
  color: var(--textColorDarker);
  font-size: 1rem;
}

.Dashboard .Title .Icons {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.Dashboard .Title .Icons .Icon {
  min-width: 5rem;
  min-height: 5rem;
  max-width: 20rem;
  background: rgba(255, 255, 255, 0.075);
  backdrop-filter: blur(30px);
  filter: saturate(150%);
  border-radius: 25px;
  padding: 1.5rem;
  text-decoration: none;
  transition: filter 250ms ease;
}

.Dashboard .Title .Icons .Icon h2 {
  color: var(--textColor);
  font-weight: 800;
}

.Dashboard .Title .Icon:hover {
  filter: saturate(200%);
}
