:root {
  --bg: #ffffff;
  --divColor: #9c9c9c;
  --divColor2: #8c8c8c;
  --divColor3: #7c7c7c;
  --divColor2Text: #1c1c1c;
  --textColor: rgba(0, 0, 0, 0.9);
  --textColorDarker: rgba(0, 0, 0, 0.7);
  --textColorEvenDarker: rgba(0, 0, 0, 0.5);
  --accentColor: #EB459E;
  --accentDarker: rgb(235, 69, 158, 0.75);
  --accentEvenDarker: rgb(235, 69, 158, 0.5);
  --accentEvenDarker2: rgb(235, 69, 158, 0.17);
  --font: "Inter", sans-serif;
  --font2: "Open Sans", sans-serif;
}

:root[data-theme="dark"] {
  --bg: #000000;
  --divColor: #1c1c1c;
  --divColor2: #2c2c2c;
  --divColor3: #3c3c3c;
  --divColor2Text: #a9a9a9;
  --textColor: rgba(255, 255, 255, 0.9);
  --textColorDarker: rgba(255, 255, 255, 0.7);
  --textColorEvenDarker: rgba(255, 255, 255, 0.5);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg);
  color: var(--textColor);
}

.Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom right,
    transparent,
    var(--accentEvenDarker2) 75%
  );
  background-size: 200%;
  transition: background-size 3s ease;
}

.Background[loaded="true"] {
  background-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Button {
  background: linear-gradient(var(--accentColor) 25%, var(--accentDarker));
  text-decoration: none;
  border-radius: 999px;
  box-shadow: var(--accentEvenDarker) 0 5px 10px -5px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  transition: opacity 250ms ease;
}

.Button:hover {
  opacity: 90%;
}

.SmallDeviceError {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
}
